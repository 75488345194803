import React, {Component} from 'react'
import {HashRouter, Switch, Route, Redirect} from 'react-router-dom'
import {getTokenStorage} from "./util/function";


import Login from "../src/page/login/index"
import Feeds from "./page/feeds";




class Router extends Component {



    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path='/' exact>
                        {
                            getTokenStorage()?
                                <Redirect to='/feeds'/>
                                :
                                <Redirect to='/login'/>
                        }

                    </Route>
                    <Route path='/login' component={Login}/>
                    <Route path='/feeds' component={Feeds}/>
                </Switch>
            </HashRouter>
        )
    }
}

export default Router
