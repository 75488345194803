export const API = {
    BASEURL: "https://wformapi.commonworths.com/api/partner/",
    LOGIN: "login",
    Feed: "feed",
    feedResource: "feedResource",
    Property:"property"

}


