import React, { useState, useEffect } from "react";
import {Button, List,Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroller';
import fileImg from "../image/file.png"
import "../style/mixin.less"
import { GetFeedList } from "../action/authAction"


const Feeds = () => {
    const getWindowSize = () => window.innerHeight
    const [Height, setHeight] = useState(getWindowSize());
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [FeedList, setFeedList] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [page, setPage] = useState(1)



    const handleResize = () => {
        setHeight(getWindowSize());
    };



    useEffect(() => {
        handleGetFeedList(1)
        // 监听
        window.addEventListener("resize", handleResize);
        // 销毁
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, [])


    const handleGetFeedList = (page) => {
        GetFeedList(page).then(res => {
            console.log(res, "121212")
            let NewData = []
            let initData = res.data.data
            let pagination = res.data.meta.pagination
            if (page === 1) {
                NewData = initData
            } else {
                NewData = FeedList.concat(initData)
            }
            setFeedList(NewData)
            setTotalPage(pagination.total_pages)
            setPage(pagination.current_page)
            setLoading(false)
        })
    }

    const handleInfiniteOnLoad = () => {
        setLoading(true)
        if (page >= totalPage) {
            setLoading(false)
            setHasMore(false)
            return;
        }
        handleGetFeedList(page + 1)
    };

    const HandleChangeShow = (item) => {
        let NewData = [...FeedList]
        for (let i in NewData) {
            if (item.id === NewData[i].id) {
                if (NewData[i].isShow) {
                    NewData[i].isShow = false
                } else {
                    NewData[i].isShow = true
                }
            } else {
                NewData[i].isShow = false
            }
        }
        setFeedList(NewData)

    }

    const VideoComponent = (feedResourceVideo) => {
        let url = feedResourceVideo[0].url
        let youtubeUrl = url.indexOf("youtu")
        let TencentUrl = url.indexOf("v.qq.com")
        if (youtubeUrl > 0) {
            let num = url.indexOf("?v=");
            if (num > 0) {
                url = url.substring(num + 3, url.length);
            } else {
                let yNum = url.indexOf(".be/");
                url = url.substring(yNum + 4, url.length);
            }
            return <div style={{ margin: "5px", width: "100%" }} >
                <iframe width="100%" height="300" src={"https://www.youtube.com/embed/" + url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        } else if (TencentUrl > 0) {
            var video_Url_Id = "";
            let Pc_Tx_Id_w = url.substr(0, url.lastIndexOf('.'));
            video_Url_Id = Pc_Tx_Id_w.substr(Pc_Tx_Id_w.lastIndexOf('/') + 1, Pc_Tx_Id_w.length);
            return <div style={{ margin: "5px", width: "100%" }}>
                <iframe width="100%" src={"http://v.qq.com/iframe/player.html?vid=" + video_Url_Id + "&tiny=0&auto=0&isAutoPlay=false"} title="Tencent video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        } else {
            return <div style={{ margin: "5px", width: "100%" }}>
                <video src={url} style={{ width: "100%" }} controls />
            </div>
        }
    }


    return (
        <div style={{ display: "flex", flex: 1, height: Height-5 + "px", justifyContent: "center", alignItems: "center", overflowX: "hidden", overflowY: "hidden" }}>
            <div style={{ width: "1270px", height: "100%", display: "flex" }}>
                <div style={{ width: "270px", height: "100%" }}>
                    <div style={{ width: "100%", marginTop: "20px", paddingLeft: "30px" }}>
                        <h1>INVESTOR</h1>
                    </div>
                </div>
                <div style={{ width: "800px", height: "100%", border: "0.5px solid rgb(235, 238, 240)" }}>
                    <div style={{ width: "100%", height: "55px", borderBottom: "0.5px solid rgb(235, 238, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <span style={{ fontSize: "20px", fontWeight: "800", marginLeft: "15px" }}>Station Posts</span>
                        <Button type="ghost" size="middle"
                            style={{ fontSize: "18px", color: "#000", border: "none", outline: "0", boxShadow: "none" }}
                            onClick={() => {
                                localStorage.removeItem("username")
                                localStorage.removeItem('token')
                                window.location.href = '/'
                            }}>Logout</Button>
                    </div>
                    
                    <div style={{ width: "100%", height: "10px", backgroundColor: "rgb(235,238,240)" }} />
                    <div style={{ width: "100%", height: Height - 75 + "px", overflow: "auto", paddingBottom: "50px", marginBottom: "10px" }} className="InfiniteScroll">
                        <InfiniteScroll
                            initialLoad={false}
                            // pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!loading && hasMore}
                            useWindow={false}
                        >
                            <List
                                dataSource={FeedList}
                                renderItem={item => {
                                    let feedResourceImage = item.feedResourceImage.data
                                    let feedResourceFile = item.feedResourceFile.data
                                    let feedResourceVideo = item.feedResourceVideo.data
                                    let PropertyData = item.property.data
                                    let date = item.created_at && item.created_at.date.substring(0, item.created_at.date.length - 7)
                                    return <List.Item key={item.id}>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <div style={{ width: "8%", display: "flex", justifyContent: "center" }}>
                                                <img style={{ width: "48px", height: "48px", borderRadius: "25px", marginTop: "10px" }} src={item.user.header_image} alt="" />
                                            </div>
                                            <div style={{ width: "90%", marginLeft: "1%" }}>
                                                <div style={{ marginBottom: "10px", display: 'flex', justifyContent: "space-between" }}>
                                                    <div>
                                                        <span style={{ fontSize: "18px", fontWeight: "700" }}>{item.create_by}</span>
                                                        <span style={{ fontSize: "14px", color: "#5e7284", marginLeft: "20px" }}>{date}</span>
                                                    </div>
                                                   

                                                </div>



                                                <div style={{ width: "100%", borderRadius: "10px", border: "1px solid #c4cfd6", marginBottom: "10px" }}>
                                                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                                        {
                                                            PropertyData.map((img, index) => {
                                                                return <div style={{ margin: "5px", padding: "0 10px", backgroundColor: "#" + img.color }} key={index}>
                                                                    <span style={{ color: "#FFF" }}>{img.name}</span>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <p style={{ color: "#5b7083", fontSize: "16px", marginLeft: "10px", marginBottom: "5px", width: "95%", height: "auto", wordWrap: "break-word", wordBreak: "break-all" }}>
                                                        {item.detail}
                                                    </p>

                                                    {
                                                        !item.isShow ?
                                                            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                                                {
                                                                    feedResourceVideo.length > 0 && VideoComponent(feedResourceVideo)
                                                                }
                                                                {
                                                                    feedResourceVideo.length === 0 && feedResourceImage.length > 0 &&
                                                                    <div style={{ margin: "5px", width: "100%" }}>
                                                                        <img src={feedResourceImage[0].url} style={{ width: "100%" }} alt="" />
                                                                    </div>
                                                                }
                                                                {
                                                                    feedResourceVideo.length === 0 && feedResourceImage.length === 0 && feedResourceFile.length > 0 &&
                                                                    <div style={{ margin: "5px" }}>
                                                                        <a href={feedResourceFile[0].url} target="_blank" rel="noopener noreferrer">
                                                                            <img src={fileImg} style={{ width: "50px", height: "50px" }} alt="" />
                                                                        </a>
                                                                    </div>
                                                                }

                                                            </div> :

                                                            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                                                {
                                                                    feedResourceVideo.map((img, index) => {
                                                                        let url = img.url
                                                                        let youtubeUrl = url.indexOf("youtu")
                                                                        let TencentUrl = url.indexOf("v.qq.com")
                                                                        if (youtubeUrl > 0) {
                                                                            let num = url.indexOf("?v=");
                                                                            if (num > 0) {
                                                                                url = url.substring(num + 3, url.length);
                                                                            } else {
                                                                                let yNum = url.indexOf(".be/");
                                                                                url = url.substring(yNum + 4, url.length);
                                                                            }
                                                                            return <div style={{ margin: "5px" }} key={index}>
                                                                                <iframe width="700" height="300" src={"https://www.youtube.com/embed/" + url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            </div>
                                                                        } else if (TencentUrl > 0) {
                                                                            var video_Url_Id = "";
                                                                            let Pc_Tx_Id_w = url.substr(0, url.lastIndexOf('.'));
                                                                            video_Url_Id = Pc_Tx_Id_w.substr(Pc_Tx_Id_w.lastIndexOf('/') + 1, Pc_Tx_Id_w.length);
                                                                            return <div style={{ margin: "5px" }} key={index}>
                                                                                <iframe width="700" height="300" src={"http://v.qq.com/iframe/player.html?vid=" + video_Url_Id + "&tiny=0&auto=0&isAutoPlay=false"} title="Tencent video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            </div>
                                                                        } else {
                                                                            return <div style={{ margin: "5px" }} key={index}>
                                                                                <video src={url} style={{ width: "700px" }} controls />
                                                                            </div>
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    feedResourceImage.map((img, index) => {
                                                                        return <div style={{ margin: "7px", position: "relative" }} key={index}>
                                                                            <img src={img.url} style={{ width: "700px" }} alt="" />
                                                                        </div>
                                                                    })
                                                                }

                                                                {
                                                                    feedResourceFile.map((img, index) => {
                                                                        return <div style={{ margin: "5px" }} key={index}>
                                                                            <a href={img.url} target="_blank" rel="noopener noreferrer">
                                                                                <img src={fileImg} style={{ width: "50px", height: "50px" }} alt="" />
                                                                            </a>
                                                                        </div>
                                                                    })
                                                                }

                                                            </div>
                                                    }
                                                </div>

                                                <span style={{ color: "#1890ff", cursor: "pointer", }} onClick={() => {
                                                    HandleChangeShow(item)
                                                }}>
                                                    {item.isShow ? "Close" : " Show More"}
                                                </span>
                                            </div>
                                        </div>
                                    </List.Item>
                                }}
                            >
                                {loading && hasMore && (
                                    <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Spin />
                                    </div>
                                )}
                            </List>
                        </InfiniteScroll>
                    </div>
                </div>
               
            </div>
        </div>
    );

}

export default Feeds

