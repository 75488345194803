import { Axios } from "../util/request"
import { API } from "../util/api";

// 登录
export const login = (data) => {
    return Axios.ajax({
        url: API.LOGIN,
        method: "post",
        data
    })
}



// 获取Feed列表
export const GetFeedList = (page) => {
    return Axios.ajax({
        url: API.Feed+"?page="+page,
        method: "get"
    })
}

// 获取Property列表
export const GetPropertyList = () => {
    return Axios.ajax({
        url: API.Property,
        method: "get"
    })
}

